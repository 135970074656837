import React from "react";
import "./App.css";
import ServicesMain from "./Pages/ServicesMain/ServicesMain";
import Banner from "./Components/Banner";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import HomePage from "./Pages/Home/HomePage";
import AboutUsMain from "./Pages/AboutUsMain";
import { Routes, Route } from "react-router-dom";
import ContactUs from "./Pages/ContactUs";
const App = () => {
  return (
    <>
      <Banner />
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/servicesMain" element={<ServicesMain />} />
        <Route path="/aboutUsMain" element={<AboutUsMain />} />
        <Route path="/contactUs" element={<ContactUs />} />
      </Routes>
      <Footer />
    </>
  );
};

export default App;
