import React, { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import Logo from "../Assets/Images/Logo.png";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <header className="bg-white shadow-md z-50 w-full">
      <div className="container mx-auto flex justify-between items-center w-[75%] py-3">
        <div className="hidden md:block lg:block xl:block">
          <input
            type="text"
            placeholder="بحث"
            className="bg-gray-200 rounded-full px-4 py-2 w-full"
          />
        </div>
        <div className="flex items-center">
          <nav className="hidden md:flex space-x-8">
            <a onClick={() => navigate("/")} className="hover:text-gray-300 cursor-pointer">
              بيت
            </a>
            <a onClick={() => navigate("/aboutUsMain")} className="hover:text-gray-300 cursor-pointer">
              معلومات عنا
            </a>
            <a onClick={() => navigate("/servicesMain")} className="hover:text-gray-300 cursor-pointer">
              خدمات
            </a>
            <a onClick={() => navigate("/contactUs")} className="hover:text-gray-300 cursor-pointer">
              اتصل بنا
            </a>
          </nav>
          <div className="flex items-center sm:ms-6 md:ms-12 lg:ms-12 xl:ms-12">
            <img
              src={Logo}
              alt="Logo"
              className="h-14 w-auto"
            />
          </div>
        </div>
        <div className="md:hidden">
          <button onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? (
              <FaTimes className="h-6 w-6" />
            ) : (
              <FaBars className="h-6 w-6" />
            )}
          </button>
        </div>
      </div>
      {isOpen && (
        <div className="md:hidden">
          <nav className="flex flex-col items-center space-y-2 pb-4">
            <a onClick={() => { navigate("/"); setIsOpen(false); }} className="hover:text-gray-300 cursor-pointer">
              بيت
            </a>
            <a onClick={() => { navigate("/aboutUsMain"); setIsOpen(false); }} className="hover:text-gray-300 cursor-pointer">
              معلومات عنا
            </a>
            <a onClick={() => { navigate("/servicesMain"); setIsOpen(false); }} className="hover:text-gray-300 cursor-pointer">
              خدمات
            </a>
            <a onClick={() => { navigate("/contactUs"); setIsOpen(false); }} className="hover:text-gray-300 cursor-pointer">
              اتصل بنا
            </a>
          </nav>
        </div>
      )}
    </header>
  );
};

export default Header;
