import React from "react";
import ServicesImage from "../../Assets/Images/handsome-auto-service-mechanic-uniform-is-standing-background-car-with-open-hood.jpg";

const ServiceSection = () => {
  return (
    <div className="relative bg-gray-900 text-white w-full h-screen ">
      <img
        src={ServicesImage}
        alt="Car Service"
        className="w-full h-full object-cover opacity-50"
      />
      <div className="absolute inset-0 flex flex-col justify-center items-end p-8 md:p-16">
        <div className="w-full flex flex-col items-end text-right pr-8 md:pr-16 lg:pr-24 xl:pr-32">
          <p className="text-[12px] md:text-sm lg:text-base xl:text-lg mb-2">
            في فنادق فن ستار، نقدم مجموعة واسعة من
          </p>
          <h2 className="text-2xl md:text-4xl lg:text-5xl font-bold mb-4">
            خدمات صيانة السيارات للحفاظ على سيارتك
            <br />
            في أفضل حالة
          </h2>
          <p className="text-sm md:text-lg mb-8">
            نحن نوفر خدمات الصيانة عالية الجودة للسيارات والتي
            <br />
            ستحافظ على سيارتك تعمل بشكل ممتاز.
          </p>
          <button className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600">
            خدماتنا
          </button>
        </div>
      </div>
    </div>
  );
};

export default ServiceSection;
