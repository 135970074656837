import React from "react";
import ServicesImagee from "../../Assets/Images/servicesImage.jpg";
import SmilingMechanic from "../../Assets/Images/smiling-mechanic-with-arms-crossed-spanner.jpg";
import MechanicPose from "../../Assets/Images/male-mechanics-posing-together-repair-shop.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import Arrow from "../../Assets/Images/arrow.png"

const AboutUs = () => {
    return (
        <div>

            <div className="w-full bg-gray-900 h-96">
                <img
                    src={ServicesImagee}
                    alt="Car Service"
                    className="w-full h-full object-cover opacity-40"
                />
                <div className="absolute inset-0 flex flex-col justify-center ">
                    <div className="w-full flex flex-col items-center text-center text-white">
                        <h2 className="text-2xl md:text-4xl lg:text-5xl font-bold mb-4">
                            معلومات عنا
                        </h2>
                        <p> بيت > إتصال </p>
                    </div>
                </div>
            </div>

            <div className="conatainer mx-10 lg:mx-40 gap-8 flex flex-col lg:flex-row mt-20">
                <div className="mb-10">
                    <p className="text-center">
                        هي شركة ناشئة جديدة مكرسة لتقديم خدمات صيانة السيارات عالية الجودة في المدينة المنورة، المملكة العربية السعودية. ومن خلال التركيز على بناء الثقة وتقديم خدمة استثنائية، نهدف إلى أن نصبح وجهة مفضلة لجميع احتياجات صيانة السيارات في المنطقة. انضم إلينا ونحن نبدأ هذه الرحلة المثيرة، بقيادة فريقنا المتفاني وبدعم من مجتمعنا المتنامي من العملاء الراضين
                    </p>

                    <div className="flex justify-end mt-10">
                        <img src={SmilingMechanic} className="w-screen shadow-2xl" />
                    </div>

                    <div className="flex justify-end mt-10">
                        <img src={MechanicPose} className="w-screen shadow-2xl" />
                    </div>
                </div>

                <div>
                    <div className="flex flex-col sm:flex-row gap-6">
                        <div className="bg-white w-full sm:w-[48%] lg:w-64  p-6 shadow-2xl text-end">
                            <FontAwesomeIcon
                                className=" bg-lime-500 text-white w-10 h-10 rounded-full p-3"
                                icon={faEye}
                            />

                            <p className="font-semibold text-sm mb-4 mt-4 ">المهمة والرؤية</p>
                            <p className="text-sm">المهمة: تقديم خدمات صيانة سيارات لا مثيل لها تضمن سلامة وثقة وطول عمر سيارتك. نحن نسعى جاهدين لتجاوز توقعات العملاء في كل خدمة نقدمها </p>
                        </div>

                        <div className="bg-white w-full sm:w-[48%] lg:w-64  p-6 shadow-2xl text-end">
                            <div className="flex justify-end ">
                            <img src={Arrow} alt="" className="w-16 h-16 "/>
                            </div>
                          

                            <p className="font-semibold text-sm mb-4 mt-4 ">فريقنا</p>
                            <p className="text-sm">يتكون فريقنا من فنيين ذوي مهارات وخبرة عالية ولديهم شغف بالسيارات. كل عضو في فريقنا ملتزم بتقديم أفضل خدمة ممكنة وضمان رضا كل عميل. نحن نستثمر في التدريب المستمر وأحدث التقنيات للبقاء في المقدمة في صيانة السيارات.</p>
                        </div>
                    </div>

                    <div className="mt-8 text-center ">
                        <button className="mt-12 mb-3 bg-lime-600 text-white py-3 px-6 rounded hover:bg-lime-600 transition">
                            لماذا اخترتنا
                        </button>

                        <p className="text-gray-700">
                            يتميز فريقنا من فنيين السيارات المعتمدين بأنهم يمتلكون المهارة في التعامل مع مجموعة واسعة من السيارات ويقومون بفحص شامل ومفصل مما يمكنك من فهم حالة سيارتك في كل زيارة

                        </p>
                    </div>

                    <div className="mt-16 text-center ">
                        <button className="mt-12 mb-3 bg-lime-600 text-white py-3 px-6 rounded hover:bg-lime-600 transition">
                            فنيين خبراء
                        </button>



                        <p className="text-gray-700">
                            يتميز فريقنا من فنيين السيارات المعتمدين بأنهم يمتلكون المهارة في التعامل مع مجموعة واسعة من السيارات ويقومون بفحص شامل ومفصل مما يمكنك من فهم حالة سيارتك في كل زيارة
                        </p>
                    </div>
                </div>
            </div>

            <div className=" text-center mb-6 mt-6">
                <button className=" bg-lime-600 text-white py-3 px-6 rounded hover:bg-lime-600 transition">
                    لماذا اخترتنا
                </button>
                <p className="text-gray-700 py-1">إصلاحات عالية الجودة
                </p>
                <p className="text-gray-700">
                    في مركز الخدمة لدينا، نحن ملتزمون بتقديم أفضل الخدمات باستخدام أحدث
                    التقنيات وأفضل الأدوات لضمان أفضل خدمة لسيارتك.
                </p>
            </div>
        </div>
    );
};

export default AboutUs;