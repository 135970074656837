import React from 'react'
import ServicesImagee from "../../Assets/Images/servicesImage.jpg"
import LocationLogo from '../../Assets/Images/location.png'
import CallLogo from '../../Assets/Images/call.png'
import MailLogo from '../../Assets/Images/mail.png'
import SettingLogo from '../../Assets/Images/real-time.png'

const ContactUsPage = () => {
  return (
    <div className="relative">

      <div className="text-white relative bg-gray-900">
        <img
          src={ServicesImagee}
          alt="Car Service"
          className="h-64 md:h-96 w-full object-cover opacity-40"
        />
        <div className="absolute inset-0 flex flex-col justify-center items-center text-center">
          <h2 className="text-xl md:text-3xl lg:text-4xl font-bold mb-2 md:mb-4">
            اتصال
          </h2>
          <p className="text-sm md:text-lg">
          الاتصال  > بيت
          </p>
          <p className="text-sm md:text-md lg:text-lg mb-4 md:mb-8 pt-10 font-[700]">
          ل السريع يعني نزول أمتعتك إلى الطريق على الفور. تمكنا   <br/>عملياتنا الفعالة ونبذل قصارى جهدنا لتقديم خدمة سريعة وفي الوقت المناسب  <br/> دون التضحية بالجودة


           
                     </p>
        </div>
      </div>

      <div className='bg-white text-center mt-8 p-4 md:p-8'>
        <p className='text-lime-800 font-bold text-lg'>الاتصال بنا</p>
        <h3 className='text-gray-700 text-xl md:text-2xl font-bold'>يتيح لك التواصل معنا</h3>
      </div>

      <div className='container mx-auto font-semibold bg-white flex flex-col lg:flex-row gap-6 lg:gap-20 mt-8 p-4'>
        <div className='bg-white shadow-2xl w-full lg:w-64 p-6'>
          <img src={LocationLogo} className='w-14 h-14 mx-auto' alt="Location" />
          <p className='text-gray-700 mt-6 text-center text-sm'>عنواننا</p>
          <p className='text-gray-700 mt-6 text-xs text-center'>رقم المبنى 4850، طريق طفيل بن عمر، حي العزيزية، المدينة المنورة، المملكة العربية السعودية</p>
        </div>
        <div className='bg-white shadow-2xl w-full lg:w-64 p-6'>
          <img src={CallLogo} className='w-14 h-14 mx-auto' alt="Call" />
          <p className='text-gray-700 mt-6 text-center text-sm'>هاتفنا</p>
          <p className='text-gray-700 mt-6 text-xs text-center'>+9665-59180831</p>
        </div>
        <div className='bg-white shadow-2xl w-full lg:w-64 p-6'>
          <img src={MailLogo} className='w-14 h-14 mx-auto' alt="Mail" />
          <p className='text-gray-700 mt-6 text-center text-sm'>بريدنا الإلكتروني</p>
          <p className='text-gray-700 mt-6 text-xs text-center'>sauditenstar.com</p>
        </div>
        <div className='bg-white shadow-2xl w-full lg:w-64 p-6'>
          <img src={SettingLogo} className='w-14 h-14 mx-auto' alt="Settings" />
          <p className='text-gray-700 mt-6 text-center text-sm'>ساعات العمل</p>
          <p className='text-gray-700 mt-6 text-xs text-center'>من : 09:00 صباحًا - جمعة : 10:00 مساءً
</p>
        </div>
      </div>

      <div className='text-center bg-white my-8 p-4 md:p-8'>
        <button className='bg-lime-600 px-4 py-2 text-white rounded'>
          لماذا إخترتنا
        </button>
        <p className='text-gray-700 mt-4 text-sm md:text-base'>
          إصلاحات عالية الجودة. في مركز الخدمة لدينا، يعد تقديم الإصلاحات والخدمات من الدرجة الأولى من أولوياتنا. نحن نستخدم أحدث المعدات وقطع الغيار الأصلية لضمان طول عمر سيارتك
        </p>
      </div>
    </div>
  )
}

export default ContactUsPage;
