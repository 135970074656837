import React from "react";
import ServiceSection from "./Services";
import AboutUsSection from "./AboutUs";
import SomeServices from "./SomeServices";

const HomePage = () => {
  return (
    <>
      <ServiceSection />
      <AboutUsSection />
      <SomeServices />
    </>
  );
};

export default HomePage;
