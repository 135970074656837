import React from "react";
import Round1 from "../../Assets/Images/round1.jpg";
import ModernCars from "../../Assets/Images/moderncars.jpg"
import WashingCars from "../../Assets/Images/male-mechanic-working-shop-car.jpg"
import CarWheels from "../../Assets/Images/car-being-taking-care-workshop.jpg"
import Round2 from "../../Assets/Images/h2.png";
import Round3 from "../../Assets/Images/h3.png";
const services = [
  {
    title: "تغييرات الزيت",
    description:
      "نحن نقدم خدمات تغيير الزيت على أيدي فنيين محترفين للحفاظ على سيارتك في حالة مثالية.",
    imageSrc: Round1, // Replace with your image path
    buttonText: "اقرأ المزيد",
    imageSrc2: ModernCars,
  },
  {
    title: "إصلاحات الفرامل",
    description:
      "تأكد من سلامة فرامل سيارتك من خلال الإصلاحات التي يقوم بها فنيون مختصون.",
    imageSrc: Round2, // Replace with your image path
    buttonText: "اقرأ المزيد",
    imageSrc2: WashingCars,
  },
  {
    title: "خدمات الإطارات",
    description:
      "نقدم مجموعة كاملة من خدمات الإطارات لضمان السلامة والأداء الأمثل لسيارتك.",
    imageSrc: Round3, // Replace with your image path
    buttonText: "اقرأ المزيد",
    imageSrc2: CarWheels,
  },
];

const ServiceSection = () => {
  return (
    <div className="bg-gray-100 py-16">
      <div className="container mx-auto px-4 text-center w-full">
        <div className="flex flex-col  items-end p-6 md:p-12">
          <h2 className="text-2xl font-bold text-lime-600 mb-4">
            المهمة والرؤية
          </h2>
          <p className="text-gray-700 mb-12">
            الهدف: تقديم خدمات صيانة سيارات فعالة وآمنة للحفاظ على عمر سيارتك.
            نحن نسعى لتحقيق مجالس أفضل توفّر لخدمات العميل في كل خدمة نقدمها.
          </p>
          <div className="flex justify-center md:justify-start w-full">
            <button className="mt-1 mb-16 bg-lime-600 text-white py-3 px-6 rounded hover:bg-lime-600 transition flex justify-start">
              عرض جميع الخدمات
            </button>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3    gap-8 mt-5  justify-center ">
            {services.map((service, index) => (
              <div
                key={index}
                className="bg-white rounded-lg shadow-lg overflow-hidden mx-auto w-full sm:w-[70%] md:w-[83%] mb-8 md:mb-0"  // Center align and remove default left/right spacing
                style={{ width: "83%" }} 
              >
                <div className="p-6">
                  <div>
                    <div className="flex justify-end mb-8">
                      <div className="flex justify-end mb-4 z-40 absolute -mt-20 sm:-mt-14 bg-white rounded-[50px]">
                        <img
                          src={service.imageSrc}
                          alt={service.title}
                          className="w-20 h-20 object-cover rounded-full shadow-md"
                        />
                      </div>
                    </div>
                    <h3 className="text-lg text-end font-semibold mb-2">
                      {service.title}
                    </h3>
                    <p className="text-gray-600 mb-6 mt-6 text-end">{service.description}</p>
                    <div className="flex justify-end">
                      <button className="bg-lime-600 text-white py-2 px-4 rounded hover:bg-lime-500 transition">
                        {service.buttonText}
                      </button>
                    </div>
                  </div>
                </div>
                <div >
                  <img
                    src={service.imageSrc2}
                    className=" shadow-xl border-4 border-white"
                  />
                </div>
              </div>
            ))}
          </div>



          <div className="mt-8 text-center w-full flex  flex-col items-center">



          <button className="mt-10 mb-6 bg-lime-600 text-white py-3 px-6 rounded hover:bg-lime-600 transition ">
              لماذا اخترتنا
            </button>

            <p className="text-gray-700">
              إرشادات عالية الجودة
              <br />

              في مركز الخدمة لدينا، نحن ملتزمون بتقديم أفضل الخدمات باستخدام
              أحدث التقنيات وأفضل الأدوات لضمان أفضل خدمة لسيارتك.
            </p>

          </div>

        </div>

      </div>



    </div>
  );
};

export default ServiceSection;
