import React from "react";
import { FaFacebookF, FaInstagram, FaLinkedin } from "react-icons/fa";
import { FiX } from "react-icons/fi";

const Banner = () => {
  return (
    <div className="text-white py-3 px-4 md:px-8 w-full bg-blue-900">
      <div className="container mx-auto flex flex-col md:flex-row justify-between items-center w-[79%]">
        <div className="flex justify-center md:justify-start space-x-4 ">
          <a href="https://www.facebook.com/sauditenstar?mibextid=LQQJ4d">
            <div className="w-6 h-6 flex items-center justify-center bg-blue-600 rounded-full text-white">
              <FaFacebookF size={15} />
            </div>
          </a>
          <a href="https://www.instagram.com/sauditenstar/?igsh=MTJ2N2JkYXE3YmQ3Zw%3D%3D">
            <div className="w-6 h-6 flex items-center justify-center bg-gradient-to-r from-purple-400 via-pink-500 to-yellow-500 rounded-full text-white">
              <FaInstagram size={15} />
            </div>
          </a>
          <div className="w-6 h-6 flex items-center justify-center bg-black rounded-full text-white">
            <FiX size={15} />
          </div>
          <a href="https://www.linkedin.com/">
            <div className="w-6 h-6 flex items-center justify-center bg-blue-700 rounded-full text-white">
              <FaLinkedin size={15} />
            </div>
          </a>
        </div>
        <div className="hidden sm:flex space-x-10">
          <span>+9665-59180831</span>
          <span>Mon : 09:00AM - Fri 10:00PM</span>
        </div>
      </div>
    </div>
  );
};

export default Banner;
