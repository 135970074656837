import React from "react";
import Image1 from "../../Assets/Images/male-mechanic-working-shop-car.jpg";
import Image2 from "../../Assets/Images/brutal-tattooed-bearded-mechanic-specialist-repairs-car-engine-which-is-raised-hydraulic-lift-garage-service-station.jpg";
import { FaPhoneAlt } from "react-icons/fa";

const AboutUsSection = () => {
  return (
    <div className="w-full mx-auto container p-4 md:p-8">
      <div className="flex flex-col md:flex-row items-start p-6 md:p-12 bg-white">
        <div className="md:w-1/2 text-center md:text-right mb-8 md:mb-0">
          <h2 className="text-xl md:text-2xl font-bold text-green-500 mb-4">
            من نحن
          </h2>
          <h3 className="text-xl md:text-2xl font-semibold text-gray-800 mb-4">
            خدمات المعدات (تخصصنا)
          </h3>
          <p className="text-gray-700 mb-6 leading-relaxed">
            نحن متخصصون في خدمات السيارات، ونوفر إصلاحات وصيانة متخصصة لضمان
            تشغيل آمن للسيارة بسلاسة. سواء كان الأمر يتعلق بأفراد أو أساطيل،
            يتمتع الفنيون لدينا بالخبرة اللازمة للتعامل مع جميع المشاكل المتعلقة
            بالمعدات.
          </p>
          <div className="space-y-4">
            <div className="flex flex-col justify-end space-y-4">
              <div className="flex justify-end items-center space-x-reverse space-x-2">
                <span className="text-green-500">رضا 100%</span>
                <div className="flex items-center justify-center w-6 h-6 rounded-full bg-blue-500">
                  <span className="text-white">✔</span>
                </div>
              </div>
              <div className="flex justify-end items-center space-x-reverse space-x-2">
                <span className="text-green-500">حركة موثوقة</span>
                <div className="flex items-center justify-center w-6 h-6 rounded-full bg-blue-500">
                  <span className="text-white">✔</span>
                </div>
              </div>
              <div className="flex justify-end items-center space-x-reverse space-x-2">
                <span className="text-green-500">موقع ملائم</span>
                <div className="flex items-center justify-center w-6 h-6 rounded-full bg-blue-500">
                  <span className="text-white">✔</span>
                </div>
              </div>
              <div className="flex justify-end items-center space-x-reverse space-x-2">
                <span className="text-green-500">أسعار جيدة ومعقولة</span>
                <div className="flex items-center justify-center w-6 h-6 rounded-full bg-blue-500">
                  <span className="text-white">✔</span>
                </div>
              </div>
            </div>
          </div>

          <div className="border-b-2 border-gray-200 my-6"></div>
          <div className="flex flex-col sm:flex-row md:flex-col lg:flex-row justify-center sm:justify-between md:justify-center lg:justify-between items-center">
            <div className="flex   items-center text-right">
              <div className="flex items-center justify-center w-8 h-8 bg-[#3a3a3a] rounded-full">
                <FaPhoneAlt className="text-white" />
              </div>
              <a href="tel:+05551234567" className="text-gray-600 ml-2">
                اتصل بنا في أي وقت
                <br />
                +9665-59180831
              </a>
            </div>
            <button className="mt-5 sm:mt-0 md:mt-5 lg:mt-0 bg-green-500 text-white py-2 px-6 rounded-full">
              MORE ABOUT US
            </button>
          </div>
        </div>
        <div className="md:w-1/2 mt-8 md:mt-0 relative">
          <img
            src={Image1}
            alt="Automotive Service"
            className="w-full h-auto max-w-md mx-auto rounded-lg object-cover"
          />
          <div className="absolute bottom-4 left-4 border-4 border-white rounded-lg shadow-lg">
            <img
              src={Image2}
              alt="Technician"
              className="w-48 h-32 rounded-lg object-cover"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUsSection;
