import React from 'react'
import { FaFacebookF, FaInstagram } from "react-icons/fa";
import { FiX } from "react-icons/fi";
import Logo from '../Assets/Images/Logo.png'

const Footer = () => {
    return (
        <div className='h-96'>

            <div className=' bg-blue-950'>

                <div className=' grid lg:grid-cols-4 md:grid-cols-4 sm:grid-cols-2 xs:grid-cols-2  pt-20'>

                    <div className='grid item text-white font-semibold text-center'>
                        <p className='mb-4'>Follow Us :</p>

                        <div className='flex justify-center gap-3'>

                            <div className="w-8 h-8 flex items-center justify-center bg-blue-600 rounded-full text-white">
                                <FaFacebookF size={20} />
                            </div>

                            <div className="w-8 h-8 flex items-center justify-center bg-black rounded-full text-white">
                                <FiX size={20} />
                            </div>

                            <div className="w-8 h-8 flex items-center justify-center bg-gradient-to-r from-purple-400 via-pink-500 to-yellow-500 rounded-full text-white">
                                <FaInstagram size={20} />
                            </div>

                        </div>

                        <div className='flex justify-center'>
                            <img
                                className='w-45 h-40 mt-6'
                                src={Logo}
                            />
                        </div>

                    </div>

                    <div className='text-center text-white pb-4 md:pb-0'>

                        <p className='mb-4 font-extrabold'>ساعة العمل</p>
                        
                        <p className=''>من : 09:00 صباحًا</p>

                        <p className=''> - جمعة : 10:00 مساءً</p>

                        {/* <p className=''>مساءً</p> */}
                    </div>

                    <div className='text-center text-white pb-4 md:pb-0'>
                        <p className='mb-6 font-extrabold'>خدماتنا</p>
                        <p className='mb-4 font-semibold'>خدمات الإطارات</p>
                        <p className='mb-4 font-semibold'>إصلاحات الفرامل</p>
                        <p className='mb-4 font-semibold'>تغييرات الزيت</p>
                        <p className='mb-4 font-semibold'>إصلاحات الفرامل</p>
                        <p className='mb-4 font-semibold'>خدمات الإطارات</p>
                        <p>تغييرات الزيت</p>
                    </div>

                    <div className='text-center text-white '>
                        <p className='mb-6 font-extrabold'>روابط سريعة</p>
                        <p className='mb-4 font-semibold'>معلومات عنا</p>
                        <p className='mb-4 font-semibold'>خدماتنا</p>
                        <p className='mb-4 font-semibold'>التعليمات</p>
                        <p className='mb-4 font-semibold'>صالة عرض</p>
                    </div>
                </div>

                <div className='pb-10'>

                    <div className="mx-40 mt-10 h-[2px] bg-gray-500 "></div>

                        <p className='text-center text-white font-semibold'>حقوق النشر © 2024 جميع الحقوق محفوظة</p>
                   
                </div>

            </div>

        </div>
    )
}

export default Footer