import React from 'react'
import ServicesImage from "../../Assets/Images/handsome-auto-service-mechanic-uniform-is-standing-background-car-with-open-hood.jpg";
import Footer from '../../Components/Footer';
import Profile1 from "../../Assets/Images/round1.jpg"
import Profile4 from "../../Assets/Images/Scheduled-Maintenance.png"
import ModernCars from "../../Assets/Images/moderncars.jpg"
import WashingCars from "../../Assets/Images/male-mechanic-working-shop-car.jpg"
import CarWheels from "../../Assets/Images/car-being-taking-care-workshop.jpg"
import Round2 from "../../Assets/Images/h2.png";
import Round3 from "../../Assets/Images/h3.png";
import Round5 from "../../Assets/Images/s5 (2).png";
import Round6 from "../../Assets/Images/1.png";
import Round7 from "../../Assets/Images/s7 (2).png";
import Round8 from "../../Assets/Images/s8.png";
import Round9 from "../../Assets/Images/s9.png";
import Round10 from "../../Assets/Images/s10.png";
import Round11 from "../../Assets/Images/s11.png";
import Round12 from "../../Assets/Images/s12.png";
import PerformingCar from "../../Assets/Images/performing-car-maintenance.jpg"
import Servicefice from "../../Assets/Images/servicesImage.jpg"
import Mechanich from "../../Assets/Images/mechanic-attaching-jumper-cables-car-battery.jpg"
import Young from "../../Assets/Images/young-bearded-men-colleagues-car-technician-mechanics-repairing-car-problem-engine.jpg"
import Painting from "../../Assets/Images/painting-machine-parts-white-paint-angle-grinder.jpg"
import ManPolish from "../../Assets/Images/man-polish-car-garage.jpg"
import topView from "../../Assets/Images/top-view-man-repairing-car.jpg"
import AutoMechanic from "../../Assets/Images/auto-mechanic-checking-running-gear-automobile-service-station-male-worker-fixing-problem-with-car-vehicle-maintenance-concept.jpg"
import Femalemodel from "../../Assets/Images/female-model-with-tattooed-body-wearing-protective-goggles-car-engine.jpg"
const servicess = [
    {
        title: "تغييرات الزيت",
        description:
            "نحن نقدم خدمات تغيير الزيت على أيدي فنيين محترفين للحفاظ على سيارتك في حالة مثالية.",
        imageSrc: Profile1,
        buttonText: "اقرأ المزيد",
        imageSrc2: ModernCars,
    },
    {
        title: "إصلاحات الفرامل",
        description:
            "تأكد من سلامة فرامل سيارتك من خلال الإصلاحات التي يقوم بها فنيون مختصون.",
        imageSrc: Round2, // Replace with your image path
        buttonText: "اقرأ المزيد",
        imageSrc2: WashingCars,
    },
    {
        title: "خدمات الإطارات",
        description:
            "نقدم مجموعة كاملة من خدمات الإطارات لضمان السلامة والأداء الأمثل لسيارتك.",
        imageSrc: Round3, // Replace with your image path
        buttonText: "اقرأ المزيد",
        imageSrc2: CarWheels,
    },
    {
        title: "الصيانة المجدولة ",
        description:
            "لصيانة الدورية هي أفضل طريقة لتجنب المشكلات الكبرى، والحفاظ على سيارتك في أفضل حالة",
        imageSrc: Profile4, // Replace with your image path
        buttonText: "اقرأ المزيد",
        imageSrc2: PerformingCar,
    },
    {
        title: "تشخيص المحرك",
        description:
            "باستخدام أحدث التقنيات، نقدم خدمات تشخيص دقيقة للمحرك",
        imageSrc:Round5, // Replace with your image path
        buttonText: "اقرأ المزيد",
        imageSrc2: Servicefice,
    },
    {
        title: "استبدال البطاريات",
        description:
            "نضمن لك استبدال البطارية بسرعة وأمان لضمان تشغيل سلس لسيارتك",
        imageSrc: Round6, // Replace with your image path
        buttonText: "اقرأ المزيد",
        imageSrc2: Mechanich,
    },
    {
        title: "فحص الكمبيوتر",
        description:
            "استفد من خدمات فحص الكمبيوتر لمساعدتك على تحديد وحل أي مشاكل تقنية في سيارتك",
        imageSrc: Round7, 
        buttonText: "اقرأ المزيد",
        imageSrc2: Young,
    },
    {
        title: "الدهان والرسام",
        description:
            "نقدم خدمات الدهان الاحترافية لضمان أن سيارتك تبدو في أفضل حالاتها",
        imageSrc: Round8,
        buttonText: "اقرأ المزيد",
        imageSrc2: Painting,
    },
    {
        title: "تفاصيل السيارة",
        description:
            "حافظ على مظهر سيارتك الداخلي والخارجي نظيفًا ولماعًا من خلال خدماتنا المتخصصة",
        imageSrc:Round9, // Replace with your image path
        buttonText: "اقرأ المزيد",
        imageSrc2: ManPolish,
    },
    {
        title: "ميكانيكية المحرك",
        description:
            "إصلاح وصيانة المحرك باستخدام أحدث التقنيات لضمان أداء قوي ودائم",
        imageSrc: Round10, // Replace with your image path
        buttonText: "اقرأ المزيد",
        imageSrc2: topView,
    },
    {
        title: "ميكانيكي سيارات",
        description:
            " خدمات ميكانيكية شاملة من قبل محترفين للحفاظ على سيارتك في حالة ممتازة",
        imageSrc: Round11, // Replace with your image path
        buttonText: "اقرأ المزيد",
        imageSrc2: AutoMechanic,
    },
    {
        title: "صيانة غلبة التروس",
        description:
            "احصل على خدمات صيانة شاملة لغلبة التروس لضمان نقل حركة سلس وأداء متكامل",
        imageSrc:Round12, // Replace with your image path
        buttonText: "اقرأ المزيد",
        imageSrc2: Femalemodel,
    },
];

const ServicesMain = () => {
    return (
        <div>
            <div>
                <div className="relative bg-gray-900 text-white w-full h-screen ">
                    <img
                        src={ServicesImage}
                        alt="Car Service"
                        className="w-full h-full object-cover opacity-40"
                    />
                    <div className="absolute inset-0 flex flex-col justify-center ">
                        <div className="w-full flex flex-col items-center text-center text-white">
                            <h2 className="text-2xl md:text-4xl lg:text-5xl font-bold mb-4">
                                تأكيد الجودة
                            </h2>
                            <p> بيت > إتصال </p>
                            <p className='py-16 mt-6 font-[700] text-lg'>لحن ملتزمون بتقديم إصلاحات وخدمات عالية الجودة <br />. باستخدام معدات متميزة وقطع غيار أصلية، تضمن مثالة سيار</p>
                        </div>
                    </div>
                </div>

                <div className="mt-8 text-center ">

                    <button className="mt-12 bg-lime-600 text-white py-3 px-6 rounded hover:bg-lime-600 transition">
                        خدمات
                    </button>

                    <h3 className="text-xl font-bold text-gray-700 mb-4 mt-4">
                        أمنة شاملة من الخدمات                    </h3>

                    <p className="text-gray-700">
                    تقدم  مجموعة واسعة من خدمات صيانة السيارات للحفاظ على سيارتك في أفضل حال فيTenstar .
                    </p>

                </div>

                <div className=" bg-gray-200 px-4 sm:px-6 md:px-8 lg:px-16 pt-8 sm:pt-12 mt-8 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6 md:gap-8">
    {servicess.map((service, index) => (
        <div
            key={index}
            className="bg-white rounded-lg shadow-lg overflow-hidden mb-16 mx-12 mt-10 h-[460px]"
        >
            <div className="p-6">
                <div className='flex justify-end mb-8'>
                    <div className="flex justify-end mb-4 z-40 absolute -mt-20 sm:-mt-16 bg-white rounded-[50px]">
                        <img
                            src={service.imageSrc}
                            alt={service.title}
                            className="w-20 h-20 object-cover rounded-full shadow-md"
                        />
                    </div>
                </div>
                <h3 className="text-lg font-semibold mb-2 text-right">
                    {service.title}
                </h3>
                <p className="text-gray-600 my-8 text-right">{service.description}</p>
                <div className="flex justify-end">
                    <button className="bg-lime-600 text-white py-2 px-4 rounded hover:bg-lime-500 transition">
                        {service.buttonText}
                    </button>
                </div>
            </div>
            <div>
                <img
                    src={service.imageSrc2}
                    alt={service.title}
                    className="w-full h-full object-cover mt-3"
                />
            </div>
        </div>
    ))}
</div>


                <div className="mt-4 mb-4 text-center ">

                    <button className="mt-12 bg-lime-600 text-white py-3 px-6 rounded hover:bg-lime-600 transition">
                        لماذا اخترتنا
                    </button>

                    <p className="text-xl text-gray-700 mb-2 mt-2">
                        اصلاحات عالية الجودة                   </p>

                    <p className="text-gray-700">
                        في مركز الخدمة لدينا، بعد تقديم الإصلاحات والخدمات من الدرجة الأولى من أولوياتنا، نحن نستخدم أحدث المعدات وقطع الغيار الأصلية لضمان طول عمر سيارتك
                    </p>

                </div>

            </div>

        </div>
    )
}

export default ServicesMain